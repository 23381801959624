import { useRouteLoaderData } from "@remix-run/react";
import { z } from "zod";
import { type loader } from "~/root";
import { throwIfUndefined } from "./throw-if";

const RootLoaderDataSchema = z.object({
  baseHomeUrl: z.string(),
  release: z.string().optional(),

  sentryDSN: z.string(),
  sentryEnvironment: z.string(),
  sentryRelease: z.string().optional(),
  sentryUserId: z.string().optional(),
  sentryUsername: z.string().optional(),

  user: z
    .object({
      username: z.string(),
      logoutUrl: z.string(),
    })
    .optional(),
  urls: z.object({
    protocol: z.string(),
    landingHost: z.string(),
    userHost: z.string(),
    userAppHost: z.string(),
  }),
});
export type RootLoaderData = z.infer<typeof RootLoaderDataSchema>;

export const getRootLoaderDataFromWindow = () =>
  RootLoaderDataSchema.safeParse(window.__remixContext.state.loaderData?.root);

export const useRootLoaderData = () =>
  throwIfUndefined(useRouteLoaderData<typeof loader>("root"), "root loader returned undefined");
